var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"divBox"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"社区达人类型","rules":[
          { required: true, message: '请选择社区达人类型', trigger: 'blur' },
        ],"prop":"talentType"}},[_c('v-select',{attrs:{"width":_vm.width,"options":_vm.talentTypeOps},on:{"change":_vm.changetalentType},model:{value:(_vm.form.talentType),callback:function ($$v) {_vm.$set(_vm.form, "talentType", $$v)},expression:"form.talentType"}})],1),_c('el-form-item',{attrs:{"label":"社区达人姓名","rules":[
          { required: true, message: '请输入社区达人姓名', trigger: 'blur' },
        ],"prop":"talentUserId"}},[_c('v-select',{attrs:{"filterable":"","width":_vm.width,"options":_vm.talentUserOps},on:{"change":_vm.changetalentUser},model:{value:(_vm.form.talentUserId),callback:function ($$v) {_vm.$set(_vm.form, "talentUserId", $$v)},expression:"form.talentUserId"}})],1),_c('el-form-item',{attrs:{"label":"社区达人图片","rules":[
          {
            required: false,
            message: '请上传社区达人封面图片',
            trigger: 'blur',
          },
        ],"prop":"imageUrl"}},[_c('v-upload',{attrs:{"limit":1,"imgUrls":_vm.form.topicPicture},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "topicPicture", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "topicPicture", $event)}}})],1),_c('el-form-item',{attrs:{"label":"是否置顶","rules":[
          { required: true, message: '请选择是否置顶', trigger: 'blur' },
        ],"prop":"isTop"}},[_c('v-select',{attrs:{"width":_vm.width,"options":_vm.isTopOps},model:{value:(_vm.form.isTop),callback:function ($$v) {_vm.$set(_vm.form, "isTop", $$v)},expression:"form.isTop"}})],1),(_vm.form.isTop === 1)?_c('el-form-item',{attrs:{"label":"置顶排序","rules":[
          {
            required: true,
            message: '请输入置顶排序值',
            trigger: ['blur', 'change'],
          },
          {
            message: '必须是数字格式',
            pattern: /^[0-9]+$/,
            trigger: 'blur',
          },
        ],"prop":"isTopSort"}},[_c('v-input',{attrs:{"placeholder":"请输入置顶排序值","min":"1","width":_vm.width,"type":"number"},model:{value:(_vm.form.isTopSort),callback:function ($$v) {_vm.$set(_vm.form, "isTopSort", $$v)},expression:"form.isTopSort"}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"风采标题","rules":[
          { required: true, message: '请输入风采标题', trigger: 'blur' },
        ],"prop":"topicTitle"}},[_c('v-input',{attrs:{"placeholder":"请输入风采标题","width":_vm.width},model:{value:(_vm.form.topicTitle),callback:function ($$v) {_vm.$set(_vm.form, "topicTitle", $$v)},expression:"form.topicTitle"}})],1),_c('el-form-item',{attrs:{"label":"风采内容","rules":[
          { required: true, message: '请输入商品内容', trigger: 'blur' },
        ],"prop":"topicContent"}},[_c('div',{attrs:{"id":"editor"}},[_c('v-ueditor',{attrs:{"maxLength":20000},model:{value:(_vm.form.topicContent),callback:function ($$v) {_vm.$set(_vm.form, "topicContent", $$v)},expression:"form.topicContent"}})],1)]),_c('el-form-item',{attrs:{"label":"发布状态","rules":[{ required: true, message: '请选择状态', trigger: 'blur' }],"prop":"status"}},[_c('v-select',{attrs:{"width":_vm.width,"options":_vm.statusOps},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }