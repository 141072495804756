//获取社区达人风采列表
const getTopicListURL = `/gateway/hc-neighbour/topic/getTopicList`;
//获取积分商城详情
const getTopicDetailURL = `/gateway/hc-neighbour/topic/getTopicInfoById`;
//新增话题
const addTopicURL = `/gateway/hc-neighbour/topic/addTopic`;
//更新话题
const updateTopicURL = `/gateway/hc-neighbour/topic/updateTopic`;
//社区达人类型
const getInitInfoURL = "/gateway/hc-neighbour/talent/getInitInfo";
//社区达人姓名
const getTalentUserListURL = `/gateway/hc-neighbour/talent/getTalentUserList`;
//详情
const getTopicInfoByIdURL = `/gateway/hc-neighbour/topic/getTopicInfoById`;

//获取园区列表
const getCommunityListUrl = `/gateway/hc-space/space/community`;

export {
  getTopicListURL,
  getTopicDetailURL,
  addTopicURL,
  updateTopicURL,
  getCommunityListUrl,
  getInitInfoURL,
  getTalentUserListURL,
  getTopicInfoByIdURL,
};
