import { mapHelper } from "@/utils/common.js";

const status = [
  {
    value: 1,
    label: "发布",
  },
  {
    value: 3,
    label: "取消",
  },
];

const { map: statusMap, setOps: setStatusOps } = mapHelper.setMap(status);

const isTop = [
  {
    value: 0,
    label: "否",
  },
  {
    value: 1,
    label: "是",
  },
];

const { map: isTopMap, setOps: setIsTopOps } = mapHelper.setMap(isTop);

// const talentType = [
//   {
//     value: "",
//     label: "创业社区达人",
//   },
//   {
//     value: "",
//     label: "",
//   },
//   {
//     value: "",
//     label: "",
//   },
//   {
//     value: "",
//     label: "",
//   },
// ];

// const { map: talentTypeMap, setOps: setTalentTypeOps } =
//   mapHelper.setMap(talentType);

export {
  statusMap,
  setStatusOps,
  isTopMap,
  setIsTopOps,
  // talentTypeMap,
  // setTalentTypeOps,
};
